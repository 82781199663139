(function($) {

//Ajax Navigation
        $('#categ-menu .categ-list ul li').click(function(){
          var catID = $(this).data("categ-id");
          
          $('.categ-list ul li').removeClass("current-cat");

          $(this).addClass("current-cat");
          NProgress.configure({ parent: "#main-header", showSpinner: false });
          NProgress.start();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
             data: {"action": "load-filter", cat: catID },
            success: function( response ) {
              //alert("enter");
              $("#home-content").remove();

              if($("#main-button").hasClass("active")){

                $("#main-nav").fadeOut("fast");
                        $("#main-button").removeClass("active");
                    }

              
              NProgress.done();
              $("#main-content").html(response);
              $("#main-content .work").hide();
              $("#main-content .work").each(function(index) {
                $(this).delay(100*index).fadeIn(500);
              });
              

                    //jQuery("#loading-animation").hide();
                    return false;
            }
          });
          
        });

})(jQuery);